import React, { useState } from "react";
import Icon, { CheckIcon, LeftChevronIcon } from "@common/Icons";
import { IconEnum } from "@constants/consts";
import { DropdownIconButton, DropdownOption } from "@typings/dropdown";
import useClickOutside from "@utils/common/useClickOutside";
import * as Styled from "./Dropdown.styled";

export interface DropdownProps {
  options: Array<DropdownOption>;
  callback: (value: string) => void;
  defaultValue?: string;
  iconButton?: DropdownIconButton | false;
  showActiveIcon?: boolean;
  onButtonClick?: () => void;
}

const DropdownButtonContent: React.FC<{
  iconButton?: DropdownIconButton | false;
  dropdownValue: DropdownOption;
}> = ({ iconButton, dropdownValue }) => {
  if (iconButton) {
    if (iconButton?.text)
      return (
        <Styled.IconButtonWrapper>
          {iconButton.icon && <Icon icon={iconButton.icon as IconEnum} />}
          <span>{iconButton.text}</span>
        </Styled.IconButtonWrapper>
      );
    if (iconButton?.icon)
      return (
        <>
          <Icon icon={iconButton.icon as IconEnum} />
          {iconButton.hiddenText && <Styled.HiddenText>{iconButton.hiddenText}</Styled.HiddenText>}
        </>
      );
  }

  return (
    <>
      <Styled.DropdownLabel>{dropdownValue.label}</Styled.DropdownLabel>
      <LeftChevronIcon />
    </>
  );
};

const Dropdown: React.FC<DropdownProps> = ({
  options,
  defaultValue,
  iconButton,
  callback,
  showActiveIcon,
  onButtonClick,
}) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const isDisabled = iconButton ? !options.length : options.length <= 1;
  const { isOpen, setIsOpen } = useClickOutside(dropdownRef);

  const [dropdownValue, setDropdownValue] = useState<DropdownOption>(
    () => (defaultValue && options.find((option) => option.value === defaultValue)) || options[0] || {}
  );
  const handleDropdownClick = () => {
    if (onButtonClick) onButtonClick();
    if (isDisabled) return;
    setIsOpen(!isOpen);
  };

  const handleSetDropdownValue = (option: DropdownOption) => {
    setIsOpen(false);
    setDropdownValue(option);
    callback(option.value);
  };

  return (
    <Styled.Dropdown isIconButton={!!iconButton} ref={dropdownRef}>
      <Styled.DropdownButton type="button" onClick={handleDropdownClick} isOpen={isOpen} isDisabled={isDisabled}>
        <DropdownButtonContent dropdownValue={dropdownValue} iconButton={iconButton} />
      </Styled.DropdownButton>
      {isOpen && (
        <Styled.DropdownItems>
          {options.map((option) => (
            <Styled.DropdownItem
              key={option.value}
              role="switch"
              aria-checked={option.value === dropdownValue.value}
              isActive={!!defaultValue && option.value === dropdownValue.value && !option.skipActiveStyle}
              isDisabled={!!option.isDisabled}
              onClick={() => !option.isDisabled && handleSetDropdownValue(option)}
            >
              {option.startIcon && <Icon icon={option.startIcon} />}
              <Styled.DropdownLabel>{option.label}</Styled.DropdownLabel>
              {showActiveIcon && option.value === dropdownValue.value && !option.skipActiveStyle && <CheckIcon />}
            </Styled.DropdownItem>
          ))}
        </Styled.DropdownItems>
      )}
    </Styled.Dropdown>
  );
};

export default Dropdown;
