import { styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const DropdownButton = styled("button")<{ isOpen: boolean; isDisabled: boolean }>(
  ({ theme, isOpen, isDisabled }) => ({
    font: fonts.buttonLarge,
    position: "relative",
    width: "100%",
    padding: theme.spacing(2, 12, 2, 4),
    height: 48,
    backgroundColor: colors.neutral0,
    color: colors.neutral90,
    border: `2px solid ${colors.neutral10}`,
    borderRadius: 7,
    cursor: "pointer",
    boxShadow: "none",
    opacity: 1,
    overflow: "hidden",
    textAlign: "left",

    [theme.breakpoints.up("md")]: {
      width: 236,
    },

    [theme.breakpoints.up("lg")]: {
      width: 300,
    },

    svg: {
      position: "absolute",
      pointerEvents: "none",
      top: 10,
      right: 14,
      transform: isOpen ? "rotate(90deg)" : "rotate(-90deg)",
      color: colors.neutral50,
    },

    ...(isDisabled && {
      opacity: 0.5,
      cursor: "default",
    }),
  })
);

export const DropdownItems = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: 236,
  right: 0,
  maxWidth: "calc(100vw - 40px)",
  padding: theme.spacing(1),
  backgroundColor: colors.neutral10,
  borderRadius: 4,
  position: "absolute",
  zIndex: zIndex.dropdown,
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

export const DropdownItem = styled("button")<{ isDisabled: boolean; isActive: boolean }>(
  ({ theme, isDisabled, isActive }) => ({
    position: "relative",
    cursor: isDisabled ? "default" : "pointer",
    opacity: isDisabled ? 0.5 : 1,
    border: "none",
    background: "none",
    display: "flex",
    gap: theme.spacing(2),
    borderRadius: 4,
    font: isActive ? fonts.bodyMediumStrong : fonts.bodyMedium,
    padding: theme.spacing(1.5, 3, 1.5, 4),
    color: colors.neutral100,
    textAlign: "left",
    "&:hover": {
      backgroundColor: colors.neutral20,
    },
    "& > div": {
      height: 24,
    },
  })
);

export const DropdownLabel = styled("span")({
  display: "inline-block",
  maxWidth: "90%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const Dropdown = styled("div")<{ isIconButton: boolean }>(({ isIconButton, theme }) => ({
  display: "inline-block",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },

  ...(isIconButton && {
    textAlign: "right",
    minWidth: 0,
    [`${DropdownButton}`]: {
      width: 40,
      height: 40,
      borderRadius: 40,
      padding: 0,
      textAlign: "center",
      backgroundColor: colors.neutral10,
      borderColor: colors.neutral10,
      opacity: 1,
      svg: {
        transform: "rotate(0deg)",
        color: colors.neutral80,
        top: 6,
        left: 6,
      },
    },
  }),
}));

export const HiddenText = styled("span")({
  "&:not(:focus):not(:active)": {
    clip: "rect(0px, 0px, 0,x, 0px)",
    clipPath: "inset(100%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: 1,
  },
});

export const IconButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
}));
