import { styled } from "@mui/material";
import { ButtonContainer } from "@common/Buttons";
import * as StyledButton from "@common/Buttons/Button/Button.styled";
import * as StyledDropdown from "@common/Dropdown/Dropdown.styled";
import { borders, colors, fonts } from "@constants/cssVariables";

export const AccountButtonContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    position: "absolute",
    right: 96,
  },
  paddingLeft: theme.spacing(3),

  [`${StyledDropdown.Dropdown}`]: {
    [`${StyledDropdown.DropdownButton}`]: {
      height: 32,
      width: 32,
      font: fonts.buttonMedium,
      backgroundColor: "transparent",
      color: colors.neutral0,
      borderRadius: borders.buttonBorderRadius,

      [theme.breakpoints.up("md")]: {
        width: "fit-content",
        height: 40,
        padding: theme.spacing(0, 3),
      },
    },

    [`${StyledDropdown.DropdownItems}`]: {
      backgroundColor: colors.neutral90,
      padding: theme.spacing(5, 1),
    },

    [`${StyledDropdown.DropdownItem}`]: {
      color: colors.neutral0,
      fontWeight: 400,

      "&:hover": {
        backgroundColor: colors.neutral80,
      },
    },
    [`${StyledDropdown.IconButtonWrapper}`]: {
      svg: {
        color: colors.neutral0,
        position: "relative",
        transform: "none",
        top: 0,
        left: 0,
        right: 0,
        height: 20,
        width: 20,
      },
    },
  },

  [`${ButtonContainer}`]: {
    height: 40,
    borderColor: colors.neutral0,
    color: colors.neutral0,

    "&:hover": {
      backgroundColor: "transparent",
      borderColor: colors.neutral0,
    },

    [theme.breakpoints.down("md")]: {
      border: "none",
      padding: 0,
      width: 32,

      "&:hover": {
        border: "none",
      },
    },

    [`${StyledButton.ButtonText}`]: {
      color: colors.neutral0,
      font: fonts.buttonMedium,
    },
    [`${StyledButton.StyledIcon} svg`]: {
      color: colors.neutral0,
    },
    [`${StyledButton.StyledIcon}, ${StyledButton.StyledIcon} svg`]: {
      width: 32,
      height: 32,
      [theme.breakpoints.up("md")]: {
        width: 20,
        height: 20,
      },
    },
  },
}));

export const AccountSkeleton = styled("div")(({ theme }) => ({
  height: 40,
  maxWidth: 32,

  svg: {
    height: 40,
    width: 40,
  },

  div: {
    height: 40,
  },

  [theme.breakpoints.up("md")]: {
    maxWidth: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
