import { FC, useEffect } from "react";
import Tealium from "@4tn/webx-analytics";
import { Theme, useMediaQuery } from "@mui/material";
import type { Session } from "next-auth";
import { SessionProvider, signIn, signOut, useSession } from "next-auth/react";
import { Button } from "@common/Buttons";
import Dropdown from "@common/Dropdown";
import SpinnerIcon from "@common/Icons/Spinner";
import { VARIANT } from "@constants/button";
import { IconEnum, LOGIN, LOGOUT, MANAGE_ACCOUNT, tenMinutes } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { noticeError, setCustomAttributes } from "@utils/common/newRelic";
import * as Styled from "./Account.styled";

interface AuthSession extends Session {
  firstName: string;
  lastName: string;
  idToken: string;
  id: string;
  profileUrl: string;
  error?: string;
  expiresAt: number;
}

const accountDropdownOptions = [
  { value: MANAGE_ACCOUNT, label: MANAGE_ACCOUNT, startIcon: IconEnum.PROFILE },
  { value: LOGOUT, label: LOGOUT, startIcon: IconEnum.LOGOUT },
];

const trackClick = (eventLabel: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.HEADER,
    event_name: TEALIUM_EVENT_NAME.HEADER_CLICK,
    event_label: eventLabel,
  });
};

const getLoginStatus = (status: string) => (status === "authenticated" ? "logged in" : "logged out");

const setTealiumDataLayer = (id: string | undefined, status: string) => {
  if (Tealium.defaultLayer.profile_gigyaid !== id) {
    const accountDataFields = {
      profile_gigyaid: id || null,
      profile_loginstatus: status,
    };
    Tealium.setDataLayer(accountDataFields);
    // Todo: check why Tealium.view event isn't sent as done for KijkWeb
  }
};

const handleAccountChange = (isLoggedIn: boolean, session?: Session | null) => {
  if (isLoggedIn) {
    const { firstName, lastName } = session as AuthSession;
    window.eventBus.dispatch("user", { isLoggedIn, user: { firstName, lastName } });
  } else {
    window.eventBus.dispatch("user", { isLoggedIn, user: null });
  }
};

const onSignIn = (data: WebXEventParams["requestSignIn"]) => {
  signIn("talpaauth", data);
};

const onSignOut = (data: WebXEventParams["requestSignOut"]) => {
  signOut(data);
};

const login = () => {
  trackClick(LOGIN);
  window.eventBus.dispatch("requestSignIn", {});
};

export const Account: FC = () => {
  const { status, data: session, update } = useSession();
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  const { firstName, profileUrl } = (session as AuthSession | null) || {};

  useEffect(() => {
    const { idToken, id, error } = (session as null | AuthSession) || {};

    if (status === "loading") {
      setCustomAttributes({
        profile_loginstatus: status,
      });
      return;
    }

    if (error) {
      noticeError(new Error("AuthError"), { error_message: error });

      if (error === "RetryRefreshToken") {
        setTimeout(update, 2000);
      } else {
        signOut();
      }
      handleAccountChange(false);
      return;
    }

    const loginStatus = getLoginStatus(status);
    setTealiumDataLayer(id, loginStatus);
    setCustomAttributes({ loginStatus });

    if (idToken) {
      handleAccountChange(true, session);
      return;
    }

    handleAccountChange(false);
  }, [session, status, update]);

  // Polling for updating session every 10 min
  useEffect(() => {
    if (!session) return;

    const interval = setInterval(() => {
      const { expiresAt } = session as AuthSession;
      // If the token is about to expire within the next 10 minutes, call the update function
      if (Date.now() >= expiresAt * 1000 - tenMinutes) {
        update();
      }
    }, tenMinutes);
    return () => clearInterval(interval);
  }, [session, update]);

  useEffect(() => {
    window.eventBus.on("requestSignIn", onSignIn);
    window.eventBus.on("requestSignOut", onSignOut);
    return () => {
      window.eventBus.off("requestSignIn", onSignIn);
      window.eventBus.off("requestSignOut", onSignOut);
    };
  }, []);

  const handleAuthSelect = (value: string) => {
    trackClick(value);
    if (value === MANAGE_ACCOUNT) {
      window.open(profileUrl, "_self");
    }
    if (value === LOGOUT) {
      window.eventBus.dispatch("requestSignOut", {});
    }
  };

  if (status === "loading")
    return (
      <Styled.AccountButtonContainer>
        <Styled.AccountSkeleton>
          <SpinnerIcon />
        </Styled.AccountSkeleton>
      </Styled.AccountButtonContainer>
    );

  return (
    <Styled.AccountButtonContainer>
      {session ? (
        <Dropdown
          options={accountDropdownOptions}
          callback={handleAuthSelect}
          iconButton={
            isSmallScreen ? { text: firstName?.charAt(0) } : { text: firstName?.slice(0, 16), icon: IconEnum.PROFILE }
          }
        />
      ) : (
        <Button variant={VARIANT.OUTLINE} startIcon={IconEnum.PROFILE} onClick={login}>
          {!isSmallScreen ? LOGIN : null}
        </Button>
      )}
    </Styled.AccountButtonContainer>
  );
};

const AccountProvider: FC = () => {
  return (
    <SessionProvider>
      <Account />
    </SessionProvider>
  );
};

export default AccountProvider;
