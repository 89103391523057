import { RefObject, useEffect, useState } from "react";

const useClickOutside = (optionsRef: RefObject<HTMLDivElement>) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return () => {};
    const handleBlur = (event: MouseEvent) => {
      let parentNode: ParentNode | null = (event.target as HTMLElement)?.parentNode;

      let isInElementTree = false;
      while (parentNode && isInElementTree === false) {
        if (parentNode === optionsRef.current) isInElementTree = true;
        parentNode = parentNode?.parentNode || null;
      }
      if (!isInElementTree) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener("click", handleBlur, false);
    return () => {
      document.body.removeEventListener("click", handleBlur);
    };
  }, [isOpen, optionsRef]);

  return { isOpen, setIsOpen };
};

export default useClickOutside;
